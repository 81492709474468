import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Button, Title, Text, Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import Contact from "../sections/common/Contact";
import imgWorkCover from "../assets/image/jpg/agency/masonry/tech-blog.jpg";

import { device } from "../utils";

const WorkSingle = () => {
  return (
    <>
      <PageWrapper>
        <Container
          fluid
          className="px-sm-5"
          css={`
            margin-top: 92px;
          `}
        >
          <img src={imgWorkCover} alt="" className="img-fluid w-100" />
        </Container>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col lg="8">
                <Text variant="tag">WEB DEVELOPMENT</Text>
                <Title variant="secSm" className="my-4">
                CMS-style blog site similar to a Wordpress site, where developers can publish their blog posts and comment on other developers’ posts as well.{" "}
                </Title>
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                  `}
                >
                 The app will follow the MVC paradigm in its architectural structure, using Handlebars.js as the templating language, Sequelize as the ORM, and the express-session npm package for authentication.
                </Text>
              </Col>
              <Col lg="4">
              <Row>
                <Col lg="4">
                <Link to="https://salty-taiga-28543.herokuapp.com/"  target="_blank"> <Button arrowRight>Live work</Button></Link>
                </Col>
              </Row>
              <Row>
                <p> </p>
              </Row>
              <Row>
                <Col lg="4">
                <Link to="https://github.com/jorgeatcabo/tech-blog/"  target="_blank"> <Button arrowRight>GitHub Repo</Button></Link>
                </Col>
              </Row>
              </Col>  
            </Row>
          </Container>
        </Section>
     
       
       
        <Box py={4}>
          <Contact />
        </Box>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
